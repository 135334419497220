import { format, isValid, parse } from "date-fns";
import { ja } from "date-fns/locale";
import { useRef, useState } from "react";
import { css } from "../../styled-system/css";
import { splitCssProps } from "../../styled-system/jsx";
import type { HTMLStyledProps } from "../../styled-system/types";

export const FormControl = (props: HTMLStyledProps<"div">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;
  return (
    <div
      className={css(
        {
          mt: 4,
        },
        styleProps,
        cssProp,
      )}
      {...restProps}
    />
  );
};

export const FormLabel = (props: HTMLStyledProps<"label">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;
  const { htmlFor, ...rest } = restProps;
  return (
    <label
      className={css(
        {
          color: "gray.500",
          display: "block",
          fontSize: "sm",
          mb: 2,
        },
        styleProps,
        cssProp,
      )}
      htmlFor={htmlFor}
      {...rest}
    />
  );
};

export const DateInput = (props: HTMLStyledProps<"input">) => {
  const [cssProps, _restProps] = splitCssProps(props);
  const { defaultValue, ...restProps } = _restProps;

  const now = format(new Date(), "yyyy-MM-dd");
  const [value, setValue] = useState(defaultValue ?? now);
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };
  const onClick = () => {
    ref.current?.showPicker();
  };

  const { css: cssProp, ...styleProps } = cssProps;

  const current = ref.current?.value;
  const date = parse(
    current ?? defaultValue?.toString() ?? "",
    "yyyy-MM-dd",
    new Date(),
  );
  const dayOfWeek = isValid(date)
    ? format(date, "(EEEEE)", { locale: ja })
    : "";

  return (
    <div
      className={css(
        {
          alignItems: "center",
          borderColor: focused ? "transparent" : "gray.300",
          borderRadius: 4,
          borderStyle: "solid",
          borderWidth: 1,
          display: "flex",
          // inputの代わりにフォーカスリングを表示する
          outlineColor: "-webkit-focus-ring-color",
          outlineStyle: focused ? "solid" : "hidden",
          outlineWidth: 2,
        },
        styleProps,
        cssProp,
      )}
    >
      <div>
        <input
          type="date"
          ref={ref}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          className={css({
            outline: "none",
            p: 2,
            pl: 4,
            pr: 0,
            // カレンダーアイコンを非表示にする
            "&::-webkit-calendar-picker-indicator": {
              display: "none",
            },
          })}
          {...restProps}
        />
      </div>
      <div>{dayOfWeek}</div>
      <div
        className={css({
          width: "100%",
        })}
      />
      <button
        type="button"
        onClick={onClick}
        className={css({
          cursor: "pointer",
          p: 2,
        })}
      >
        <img src="/images/calendar_today.svg" alt="日付を選択する" />
      </button>
    </div>
  );
};

export const Input = (props: HTMLStyledProps<"input">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;
  return (
    <input
      className={css(
        {
          borderColor: "gray.300",
          borderRadius: 4,
          borderStyle: "solid",
          borderWidth: 1,
          display: "block",
          padding: 2,
          width: "100%",
        },
        styleProps,
        cssProp,
      )}
      {...restProps}
    />
  );
};

export const Button = (props: HTMLStyledProps<"button">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;

  const buttonColor = restProps.type === "submit" ? "blue.500" : "gray.300";
  const fontColor = restProps.type === "submit" ? "white" : "black";

  return (
    <button
      className={css(
        {
          bg: buttonColor,
          borderRadius: 4,
          color: fontColor,
          px: 4,
          py: 2,
          cursor: "pointer",
        },
        styleProps,
        cssProp,
      )}
      type={props.type}
      {...restProps}
    />
  );
};
